.chart_card {
  border: 1px solid #40404d;
  border-radius: 4px;
  margin-right: 24px;
  margin-bottom: 20px;
  flex: 1;
  max-width: calc(100vw - 30px);
  background: #2f2f39;

  &:last-child {
    margin-right: 0;
  }

  &_title {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    padding: 10px;
    background: #1e1f26;
  }

  &_content {
    background: #2f2f39;
    padding-bottom: 10px;
    position: relative;
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
