$color-bg: #19375a;
$color-main: #234870;
$color-secondary: #1f5d8e;
$color-active: #1890ff;
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");

body {
  color: #fff;
  background: #1e1f26;
  font-family: "IBM Plex Sans", sans-serif;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c44d;
  border-radius: 16px;
  border: 2px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

main {
  width: 100%;
  height: 100%;
}

.ecocard .ant-card-head {
  color: #fff;
  background: #323c48;
  border-bottom: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

.page-title {
  font-size: 20px;
  line-height: 1;
}

.formItem .ant-form-item-label label {
  color: #fff;
}

.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}

.ant-modal-header {
  background: #323c48;
  padding-bottom: 0;
  border-bottom: 0;
}
.ant-modal-title {
  color: #fff;
}
.ant-modal-content {
  background: #323c48;
}

.ant-card-head-title {
  font-size: 16px;

  small {
    text-transform: none;
  }
}

.ant-statistic-title,
.ant-statistic-content {
  color: #98a4b9;
}

.ant-descriptions-item-content {
  background: #fff;
}

.ant-card {
  color: #98a4b9;
  border-radius: 10px;
}
.ant-card-head {
  color: #fff;
  background: #323c48;
  border-bottom: 2px solid #e8e8e8;
}
.ant-card-body {
  background: #323c48;
}
.ant-card-bordered {
  border: none;
}

// .ant-table-small {
//   border: none;
// }

// .ant-table-thead {
//   vertical-align: top;
// }
// .ant-table-thead > tr > th {
//   color: #fff;
//   border-bottom: 1px solid rgba(152, 164, 185, 0.25);
// }
// .ant-table-tbody > tr:hover,
// .ant-table-tbody > tr:hover > td {
//   background: #2a323c !important;
// }
// .ant-table-tbody > tr > td {
//   color: #fff; /*#98a4b9;*/
//   border-bottom: 1px solid rgba(152, 164, 185, 0.25);
// }

/* table styles */
.ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-table-small > .ant-table-content .ant-table-header,
.ant-table-thead {
  background: #1f2739;
}
.ant-table-thead > tr > th {
  vertical-align: top;
  color: #fff;
  font-size: 12px;
  // text-align: center;
  line-height: 1;
  // padding: 12px 0 !important;
  border-bottom-color: rgb(46, 46, 46);

  .ant-table-column-title {
    font-size: 14px;
  }
}

.map-legend {
  min-width: 60%;
  width: 80%;
  margin: 5px 0 0 10px;
  padding: 0 24px;
  display: flex;
  justify-content: flex-end;
  gap: 28px;
  position: absolute;
  transform: translate(20%, calc(5px / 2 - 200%));
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-wrap: nowrap;
  }
  p {
    margin-bottom: 0;
  }
  span {
    width: 7px;
    display: block;
    height: 7px;
    border-radius: 50%;
  }
}

.p-10 {
  padding: 10px;
}

.datepicker-wrap {
  flex: 1;

  span.ant-calendar-picker {
    width: 49%;
    margin-right: 1%;
  }
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0 !important;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0 !important;
}

.ant-table-body {
  background: #272e48 /*#3a3a3a*/ !important;
  margin: 0 !important;
}
.ant-table-tbody > tr {
  cursor: pointer;
}

.ant-table-tbody > tr:nth-child(2n) {
  background-color: #2c3446; /*#343434;*/
}
.ant-table-tbody > tr:nth-child(2n + 1) {
  background-color: #323c50; /*#343434;*/
}
.ant-table-tbody > tr > td {
  vertical-align: top;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  // text-align: center;
  padding: 20px 8px !important;
  border-bottom-color: #000;
}
// .ant-table-tbody > tr > td:nth-child(2) {
//   text-align: left;
// }

.ant-table-tbody > tr:hover,
.ant-table-tbody > tr:hover > td {
  background: #40566f !important;
}

// .ant-table-body::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   background-color: #f5f5f5;
// }
// .ant-table-body::-webkit-scrollbar-track {
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }
// .ant-table-body::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #f5f5f5;
// }
// .ant-table-body::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #cdcdcd;
// }

// .scrollbar::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   background-color: #f5f5f5;
// }
// .scrollbar::-webkit-scrollbar-track {
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }
// .scrollbar::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #f5f5f5;
// }
// .scrollbar::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   background-color: #cdcdcd;
// }
// ::-webkit-scrollbar {
//   width: 8px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.card {
  .title {
    text-align: center;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 4px;
  }
}

.text-center {
  text-align: center;
}

.food_item {
  width: calc(20% - 24px);
  height: 250px;
  margin-right: 24px;
  margin-bottom: 24px;
  background: #2f2f39;
  border: 1px solid #40404d;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  padding: 24px;

  &:hover img {
    transform: translate(0, -10px);
  }

  &__price {
    font-size: 18px;
    line-height: 26px;
  }

  &__unittype {
    opacity: 0.7;
  }
}

.video-monitoring .iframe {
  height: 85vh;
}

.video_item {
  background-color: $color-secondary;
  padding: 7px 5px;
  margin: 0 5px 7px 0;
  cursor: pointer;
  transition: 0.5s all;
  &:hover {
    background-color: $color-active;
  }
  p {
    text-align: center;
    margin-bottom: 0;
  }
}

.video_item.active {
  background-color: $color-active;
}

.content-wrap .unit {
  position: absolute;
  font-size: 11px;
  top: 4px;
  margin-left: 5px;
}
.content-wrap .text-color-grey {
  width: 50%;
  font-size: 11px;
  margin-top: -5px;
}
.text-color-grey {
  color: #98a4b9;
}
.content-wrap h2 {
  font-size: 30px;
  margin-top: 0;
  position: relative;
}
.content-wrap .border-dashed {
  margin-top: -10px;
  border-bottom: 1px dashed;
  width: 50%;
  padding-bottom: 5px;
}
.air-quality-widget img {
  width: 80px;
  display: block;
  margin: auto;
  margin-top: -10px;
}

.air-quality-widget .unit .top-tail {
  position: absolute;
  top: -5px;
  font-size: 8px;
}
.air-quality-widget .unit {
  position: relative;
}
.air-quality-widget .content-wrap h4 {
  font-size: 18px;
  position: relative;
}
.content-wrap h4 span.tail {
  position: absolute;
  font-size: 10px;
  top: 10px;
}

.air-quality-widget {
  // text-align: center;
  padding-bottom: 30px;
  // border-bottom: 1px dotted #fff;
  margin-bottom: 30px;
}
.widget-blocks {
  text-align: left;
}
.widget-blocks h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.graph-vertival {
  position: absolute;
  top: 10px;
  right: 30px;
}
.content-wrap {
  position: relative;
  // padding-left: 20px;
}
.graph-vertival ul {
  position: relative;
}

.graph-vertival li {
  list-style: none;
  width: 10px;
  height: 15px;
  background-color: #fff;
  margin-bottom: 2px;
}
.graph-vertival li.red {
  background-color: #f44336;
}
.graph-vertival li.orange {
  background-color: #ff6600;
}
.graph-vertival li.yellow {
  background-color: #ffeb3b;
}
.graph-vertival li.green {
  background-color: #32cd66;
}
.graph-vertival li:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.graph-vertival li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.graph-vertival ul span {
  position: absolute;
  left: 18px;
  line-height: 0;
  font-size: 20px;
}

// Temperature widgets

.value.small-text .ant-statistic-content {
  font-size: 16px;
  font-weight: bold;
}

.light-blue .ant-statistic-content {
  color: #01a4b4;
}

.light-green .ant-statistic-content {
  color: rgba(154, 186, 47, 1);
}

.circleData {
  background-image: linear-gradient(
    to right,
    rgb(16, 142, 233),
    rgb(135, 208, 104)
  );
  border-radius: 50%;
  height: 200px;
  width: 200px;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.circleData div {
  flex: 0 0 100%;
  color: #fff;
  font-size: 16px;
}

.temperature-difference {
  color: #fff;
  font-size: 18px;
}

div.temperature-current {
  font-size: 48px;
}

.current-time-wrapper {
  position: absolute;
  top: 0px;
  right: 15px;
  display: flex;
  padding: 10px;
  background: #2F2F39;
}

.card-content {
  display: flex;
  justify-content: space-around;
  // align-items: center;
  .ant-card {
    display: flex;
    flex-direction: column;
    .ant-card-body {
      flex: 1;
    }
  }
}

.day-forecast-card {
  border: 1px solid #40404D;
  overflow: hidden;
  max-width: 22%;
  width: 22%;
  margin-top: 20px;
}

.day-forecast-card .ant-card-body {
  padding: 15px;
}

.day-forecast-image img {
  height: 30px;
}

.day-forecast-card {
  .ant-card-body {
    div {
      padding: 2.5px 0;
      font-size: 12px;
    }
  }
}

.day-forecast-wind,
.day-forecast-side,
.day-forecast-humidity {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px 0;
}

.weather-panel {
  text-align: center;
  display: flex;

  &.spaced {
    justify-content: space-between;
  }

  .panel-image {
    flex: 1;
  }
  .panel-circle {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 8px solid rgba(95, 96, 97, 0.5);

    .arrow {
      // transform: rotate(337deg);
      position: absolute;
      z-index: 200;
      top: 0;
      left: 50%;
      margin-left: -5px;
      width: 10px;
      height: 50%;
      transform-origin: 50% 100%;
      transition-duration: 3s;
    }
    .arrow:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      z-index: 9;
      left: 2px;
      top: -5px;
      border: 2px solid RGBA(255, 255, 255, 0.8);
      border-radius: 100%;
    }
    .arrow:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      height: 10px;
      width: 10px;
      background-color: NONE;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 9px 0 9px;
      border-color: RGBA(255, 121, 58, 1) transparent transparent transparent;
      transform: translate(-50%, -50%);
      transition-duration: 3s;
    }
  }
  .panel {
    flex: 0 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      font-size: 16px;
      line-height: 1;
    }
  }
}

.weather-card {
  height: 100%;
  &.ant-card {
    display: flex;
    flex-direction: column;
    .ant-card-body {
      flex: 1;
    }
  }
}

.notification {
  color: #000;
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 10px;

  .location {
    line-height: 1;
    margin-bottom: 2px;
  }

  .date {
    font-size: 11px;
    line-height: 1;
    color: #888;
    margin-bottom: 6px;
  }

  .title {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .text {
    font-size: 13px;
    line-height: 1;
  }
}

.infocard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-card-head {
    min-height: auto;
    background: #323c48;
    padding: 0 10px;

    .ant-card-head-title {
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      padding: 10px 0 0;
    }
  }
  .ant-card-body {
    flex: 1;
    background: #323c48;
    padding: 10px;
  }
}

.ecocard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-card-head {
    min-height: auto;
    background: #323c48;
    padding: 0 10px;

    .ant-card-head-title {
      font-size: 16px;
      // line-height: 1;
      text-transform: uppercase;
      padding: 10px 0 0;
    }
  }
  .ant-card-body {
    flex: 1;
    background: #323c48;
    padding: 10px;
  }

  &:hover {
    background: #323c48;
    transform: scale(1.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  &.no-transform:hover {
    // background: #323c48;
    transform: scale(1);
    // box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    // z-index: 10;
  }
}

.infoblock__subtitle {
  color: #fff;
  font-size: 11px;
  line-height: 1;
  margin: 0 0 0.2vw;
}
.infoblock__stats {
  margin: 0 0 0.4vw;
}
.infoblock__stats--highlighted {
  border: 2px solid rgb(78, 78, 78);
  cursor: pointer;
  border-radius: 6px;
}
.infoblock__stats--highlighted.active,
.infoblock__stats--highlighted:hover {
  border-color: #33cc66;
}

.infoblock__stat {
  position: relative;
  display: flex;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  padding: 0.2vw 0.4vw;
  border-bottom: 0.1vw solid #2f3243;
}
.infoblock__stat:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.infoblock__stat:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.infoblock__stat:nth-child(2n) {
  background: #282a38;
}
.infoblock__stat:nth-child(2n + 1) {
  background: #2b2e3d;
}
.infoblock__stat__title {
  font-size: 13px;
  flex: 1;
}

.infoblock__subtitle {
  width: 90%;
}

.infoblock__stat__number {
  position: absolute;
  right: 3%;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #323c50;
  border-color: #000000;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #ffffff;
}

.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(255, 255, 255, 0.75);
  -webkit-transition: none;
  transition: none;
}

.ant-pagination-item-ellipsis {
  color: #ffffff;
}

:root
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
:root
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  font-size: 12px;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  color: #ffffff;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-item-active a {
  color: #ffffff;
}

.date-period {
  color: #2ba6ff;
}

.appeal-numbers {
  margin-bottom: 7.5px;
}

.appeal-numbers .ant-card-body {
  background-color: #2a323c;
  padding: 15px;
}

.operational-summary * {
  color: #fff;
}

.operational-summary {
  .ant-list-lg .ant-list-item {
    padding-bottom: 10px;
    margin-bottom: 5px;
  }

  .ant-list-split .ant-list-item {
    border-bottom: 1px solid #7b7b7b;
  }

  .ant-card-body {
    padding: 0 20px 20px;
  }

  span.date {
    color: #989898;
    font-size: 12px;
  }

  small {
    font-size: 13px;
    color: #9a9a9a !important;
    position: relative;
    height: 30px;
    top: -10px;
    display: block;
  }

  .article-text {
    font-size: 12px;
    line-height: 1.7;
    height: 120px;
    overflow: auto;
    padding: 2px;
  }

  .ant-list-item-meta-description {
    color: #b9b9b9;
  }
  .ant-list-item-meta-title {
    color: #ffffff;
  }
  .ant-pagination-item,
  .ant-pagination-item-link {
    background-color: initial;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.pie-legend {
  font-size: 14px;
  ul {
    list-style: none;
    padding-left: 20px;
    li {
      display: inline-block;
      margin-right: 8px;
    }
  }
  span {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
  }
  b {
    padding-left: 5px;
    font-size: 15px;
    font-weight: normal;
  }
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #202333;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.custom-calendar .ant-fullcalendar-header {
  // display: none;
  color: #2b2e3d;
}

.custom-calendar {
  .ant-fullcalendar-value,
  .ant-fullcalendar-column-header-inner {
    color: #fff;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    overflow-y: hidden;
  }

  .ant-fullcalendar-fullscreen
    .ant-fullcalendar-selected-day
    .ant-fullcalendar-date,
  .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
    background: #3d4754;
  }
  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }

  .ant-badge-status-text {
    color: #fff;
  }

  .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
    display: none;
  }
}

.ant-calendar-panel {
  .ant-calendar-date-input-wrap {
    margin-left: 90px;
  }
  .ant-calendar-range-middle {
    left: 47%;
  }
}

.loading-wrap {
  position: absolute;
  height: 100vh;
  text-align: center;
  width: 100%;
  z-index: 99;
  background-color: rgba($color: #000000, $alpha: 0.6);
}

.ant-modal-close-x {
  color: #fff;
}

.title-appeal {
  height: 80px;
}

.appeals-citizen {
  .ant-statistic-title {
    font-size: 13px;
    position: relative;
    top: -9px;
    height: 25px;
    line-height: 1.3;
  }
}

.row.error td {
  color: #f5222d;
}

.category-analytics {
  .custom-legend {
    position: absolute;
    left: -25%;
    top: 12%;
    max-width: 350px;
    padding: 5px 10px;
    border-radius: 3px;
    width: 50%;
    background: #3a619242;
    max-height: 60%;
    overflow-y: auto;
    z-index: 3;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      line-height: 1.2;
      font-size: 12px;
    }
  }
}

.appeals-monitoring {
  table a:hover {
    background: #3d4b66;
  }
}

.ant-modal-header {
  span button {
    margin-right: 20px;
  }
}
