.weather_wrapper {
  border: 1px solid #40404D;
  border-radius: 4px;
  padding: 10px 0 0;
  margin-bottom: 10px;
  &_title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  &_content {
    height: 240px;
    padding: 20px;
    background-color: #2F2F39;
  }
  p {
    font-size: 12px;
    color: #CAE9FF;
    text-align: right;
    padding-right: 20px;
    margin-top: 10px;
  }
}

.filter_label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

.filter_select  {
  :global(.ant-select-selection) {
    min-height: 34px !important;
    padding-top: 6px;
    padding-bottom: 7px;
    background-color: #2f2f39;
    border: 1px solid #40404d;
    border-radius: 4px;
    color: #ffffff;

    &__clear {
      padding-top: 6px;
      padding-bottom: 7px;
      background-color: #2f2f39;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  :global(.ant-select-arrow) {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}