.slider_custom {
  margin-bottom: 20px !important;

  :global(.ant-slider-rail) {
    height: 2px;
  }
  :global(.ant-slider-track) {
    height: 2px;
  }
}
