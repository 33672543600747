/* Progress bar */
.progressbar {
  color: #fff;
  margin: 10px 0 0;

  .progressbarHeader {
    display: flex;
    justify-content: space-between;
    font-size: 0.8vw;

    .progressbarTitle {
      text-transform: uppercase;
    }
  }

  .progressbarBars {
    display: flex;
    padding: 4px;
    margin-bottom: 10px;
    .progressbarBar {
      height: 30px;
      &:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    }
  }

  .progressbarStats {
    width: 100%;
    .progressbarStat {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      td {
        font-size: 0.8vw;
        padding: 0.4vw 0.4vw;
        .progressbarWrapper {
          border: 1px solid;
          border-radius: 8px;
          overflow: hidden;

          span {
            position: relative;
            left: 0;
          }
        }
      }
    }
  }
}
