.statistics_title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  margin-bottom: 24px;
  border: 1px solid #40404D;
}

.statistics_column {
  margin-bottom: 10px;
  &_row {
    background: #2F2F39;
    border: 1px solid #40404D;
    border-radius: 4px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
}

.physical_volume_column {
  border: 1px solid #40404D;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 20px;
  &_title {
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &_content {
    background: #2F2F39;
    padding: 20px 0;
  }
}

.inflation_item {
  border: 1px solid #40404D;
  border-radius: 4px;
  margin-bottom: 20px;
}